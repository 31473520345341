<template>
  <div
    :class="[
      'blog-container',
      {
        'logged-in': userTokenAvailable && !isMobile,
        'not-logged-in': userTokenAvailable && isMobile,
        collapsed: this.$root.isCollapsed && !isMobile,
        notification: userTokenAvailable && this.$root.warning && this.$root.warning !== 'NORMAL' && !isMobile,
      },
    ]"
  >
    <div class="routes" v-if="!userTokenAvailable">
      <span @click="navigateTo('/')">Home</span
      ><i class="fa-solid fa-angle-right"></i
      ><span @click="navigateTo('/blogs')">Blogs</span
      ><i class="fa-solid fa-angle-right"></i><span>{{ blogTitle }}</span>
    </div>

    <div
      class="blog-header"
      :style="{ width: userTokenAvailable ? '95%' : '90%' }"
    >
      <div class="blog-header-2">
        <h1>{{ blogData?.title }}</h1>
        <div class="blog-meta">
          <span class="author"
            >By <strong>{{ blogData?.author }}</strong> •
            {{ formattedPublicationDate(blogData?.publicationDate) }}</span
          >•<strong class="category">{{ blogData?.category }}</strong>
        </div>
        <div class="author" style="margin-top: -20px">
          {{ blogData?.readTime }} min read
        </div>
        <div class="social-media-share">
          <div
            v-for="(option, index) in this.shareOptions"
            :key="index"
            class="tooltip-message-profile"
            @click="this.handleShare(option.type)"
          >
            <img
              :src="this.$root.getCDNlink(option.icon)"
              :alt="`Share referral link on ${option.label} - Credencedata`"
              class="social-media-icons-blogs"
              style="width: 25px; height: 25px"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="blog-wrappper" :class="{loginclass: userTokenAvailable && !isMobile}">
      <div
        class="toc-container"
        :class="{loggedInToc: userTokenAvailable && !isMobile}"
        v-if="tableOfContents.length"
        :style="{
          position: !isMobile && 'sticky',
          top: !isMobile && '120px',
          width: !isMobile && '70vw',
        }"
      >
        <h3>Table of Contents</h3>
        <div class="toc-div"></div>
        <ul class="toc">
          <li v-for="(heading, index) in tableOfContents" :key="index">
            <a href="javascript:void(0);" @click="scrollToSection(heading.id)">
              {{ heading.text }}
            </a>
          </li>
        </ul>
      </div>
      <div
        class="blog-content-wrapper"
        :style="{ width: userTokenAvailable && '95%' }"
      >
        <div
          class="blog-body"
          :style="{ margin: userTokenAvailable ? '0 4vw' : '0 10vw 0 0' }"
        >
          <article class="blog-content" v-html="blogData?.content"></article>
          <div class="tags">
            <h4>Tags</h4>
            <div class="tags-list">
              <div
                class="tags-wrapper"
                v-for="(tag, index) in mappedTags"
                :key="index"
              >
                <i class="fa-solid fa-tag"></i>
                <span class="tag-title">{{ tag }}</span>
              </div>
            </div>
          </div>
          <!-- <div class="tags">
            <h4>Comments (0)</h4>
            <div class="blog-comment-input">
              <div class="input-wrappper">
                <input
                  type="name"
                  placeholder="Full name"
                  class="enter-email-address"
                  autocomplete="off"
                  id="name"
                  v-model="name"
                  for="name"
                />
              </div>
              <div class="input-wrappper">
                <input
                  type="email"
                  placeholder="Enter email address"
                  class="enter-email-address"
                  autocomplete="off"
                  id="userEmail"
                  v-model="userEmail"
                  for="userEmail"
                />
              </div>
            </div>
            <div class="blog-comment-message">
              <textarea
                v-model="comment"
                placeholder="Write a comment"
              ></textarea>
            </div>
            <button class="btn-comments" type="button">
              Be the first to write a comment
            </button>
          </div> -->
        </div>

        <div class="social-media-share-2">
          <div
            v-for="(option, index) in this.shareOptions"
            :key="index"
            @click="this.handleShare(option.type)"
          >
            <img
              :src="this.$root.getCDNlink(option.icon)"
              :alt="`Share referral link on ${option.label} - Credencedata`"
              class="social-media-icons-blogs"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="related-blogs" v-if="relatedBlogs.length">
      <h2>Related Blogs</h2>
      <div class="carousel-container">
        <div class="carousel-wrapper">
          <div
            class="carousel-card"
            v-for="blog in relatedBlogs"
            :key="blog.id"
            @click="redirectToBlog(blog)"
          >
            <img
              :src="this.$root.getCDNlink(blog.bannerImage)"
              alt="Related Blog Banner"
            />
            <div
              class="blog-meta"
              style="
                color: #8f8f8f;
                font-size: small;
                text-align: left;
                line-height: 16px;
                padding: 0 20px;
                margin-top: 10px;
              "
            >
              <span class="author"
                >By <strong>{{ blog?.author }}</strong> <br />
                {{ formattedPublicationDate(blog?.publicationDate) }}</span
              >
            </div>
            <h3 style="padding: 0 20px">{{ blog.title }}</h3>
          </div>
        </div>
      </div>
      <div class="categories">
        <h2>Explore other Categories</h2>
        <div class="tags-list">
          <div
            class="tags-wrapper"
            style="
              border: none;
              background: #c5def8;
              cursor: pointer;
              font-weight: 600;
              color: #11365a;
            "
            v-for="(category, index) in othercategories"
            :key="index"
            @click="redirectToBlogsList(category)"
          >
            <!-- <i class="fa-solid fa-tag"></i> -->
            <span class="tag-title">{{ category }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer class="footer-instance" v-if="!userTokenAvailable"></Footer>
</template>
<script>
import { GetBlog } from "@/api/APIs";
import { useHead } from "@unhead/vue";
import Footer from "@/components/HomePage/Footer/Footer.vue";
import VueCookies from "vue-cookies";

export default {
  name: "BlogPage",
  props: ["blogTitle", "blogId"],
  components: {
    Footer,
  },
  data() {
    return {
      blogData: null,
      relatedBlogs: [],
      tableOfContents: [],
      othercategories: [],
      isMobile: window.innerWidth < 1156,
      userToken: null,
      shareOptions: [
        { type: "link", label: "Share Link", icon: "assets/SocialMediaIcons/link.webp" },
        { type: "whatsapp", label: "Whatsapp", icon: "assets/SocialMediaIcons/whatsapp.webp" },
        { type: "linkedin", label: "LinkedIn", icon: "assets/SocialMediaIcons/linkedin.webp" },
        { type: "twitter", label: "Twitter", icon: "assets/SocialMediaIcons/x.webp" },
      ],
    };
  },
  beforeMount() {
    this.userToken = VueCookies.get("token");
  },
  async mounted() {
    await this.fetchBlogDetails();
    this.makeImagesResponsive();
  },
  computed: {
    userTokenAvailable() {
      return this.userToken !== null;
    },
    mappedTags() {
      return this.blogData?.tags
        ?.split(",")
        .map((tag) => this.toCamelCase(tag.trim()));
    },
  },
  methods: {
    makeImagesResponsive() {
      const images = document.querySelectorAll(".blog-content img");
      const paragraphs = document.querySelectorAll(".blog-content p");
      const headers = document.querySelectorAll(
        ".blog-content h1, .blog-content h2, .blog-content h3, .blog-content h4, .blog-content h5, .blog-content h6"
      );
      images.forEach((img) => {
        img.style.maxWidth = "100%";
        img.style.height = "auto";
        img.style.display = "block";
        img.style.borderRadius = "10px";
      });
      if (window.innerWidth <= 768) {
        paragraphs.forEach((p) => {
          p.style.fontSize = "14px";
          p.style.lineHeight = "1.4";
        });
        headers.forEach((header) => {
          switch (header.tagName.toLowerCase()) {
            case "h1":
              header.style.fontSize = "1.5em";
              break;
            case "h2":
              header.style.fontSize = "1.25em";
              break;
            case "h3":
              header.style.fontSize = "1.1em";
              break;
            case "h4":
              header.style.fontSize = "1em";
              break;
            case "h5":
              header.style.fontSize = "0.875em";
              break;
            case "h6":
              header.style.fontSize = "0.75em";
              break;
          }
        });
      }
    },
    toCamelCase(tag) {
      return tag
        .toLowerCase()
        .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
          index === 0 ? match.toLowerCase() : match.toUpperCase()
        );
    },
    redirectToBlogsList(category) {
      this.$router.push(`/blogs/${category}`);
    },
    redirectToBlog(blog) {
      window.location.href = `/blog/${blog.slug}/${blog.id}`;
    },
    formattedPublicationDate(publishDate) {
      if (!publishDate) return "Unknown Date";

      const date = new Date(publishDate);
      if (isNaN(date.getTime())) return "Invalid Date";

      return new Intl.DateTimeFormat("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      }).format(date);
    },
    setMetaTags() {
      useHead({
        title: this.blogData?.seotitle || this.blogData?.title,
        meta: [
          {
            name: "description",
            content: this.blogData?.meta || this.blogData?.summary || "",
          },
          {
            name: "keywords",
            content: this.blogData?.tags || "",
          },
          {
            property: "og:title",
            content: this.blogData?.seotitle || this.blogData?.title,
          },
          {
            property: "og:description",
            content: this.blogData?.meta || this.blogData?.summary || "",
          },
          {
            property: "og:image",
            content: this.blogData?.bannerImage || "",
          },
          {
            property: "og:image:width",
            content: "1200",
          },
          {
            property: "og:image:height",
            content: "630",
          },
          {
            property: "og:url",
            content: window.location.href,
          },
          {
            name: "twitter:card",
            content: "summary_large_image",
          },
          {
            name: "twitter:title",
            content: this.blogData?.seotitle || this.blogData?.title,
          },
          {
            name: "twitter:description",
            content: this.blogData?.meta || this.blogData?.summary || "",
          },
          {
            name: "twitter:image",
            content: this.blogData?.bannerImage || "",
          },
        ],
      });
    },
    handleShare(type) {
      //   this.toggleTooltip();
      const currentUrl = window.location.href;
      let shareUrl;

      switch (type) {
        case "whatsapp":
          shareUrl = `https://wa.me/?text=${encodeURIComponent(currentUrl)}`;
          break;
        case "linkedin":
          shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
            currentUrl
          )}`;
          break;
        case "twitter":
          shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
            currentUrl
          )}`;
          break;
        case "link":
          this.copyToClipboard();
          return;
      }

      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      if (isMobile) {
        const appUrl = this.getAppUrl(type, currentUrl);
        window.location.href = appUrl;
        setTimeout(() => {
          window.location.href = shareUrl;
        }, 1000);
      } else {
        window.open(shareUrl, "_blank");
      }
    },
    getAppUrl(type, url) {
      switch (type) {
        case "whatsapp":
          return `whatsapp://send?text=${encodeURIComponent(url)}`;
        case "linkedin":
          return `linkedin://shareArticle?mini=true&url=${encodeURIComponent(
            url
          )}`;
        case "twitter":
          return `twitter://post?message=${encodeURIComponent(url)}`;
        default:
          return "";
      }
    },
    copyToClipboard() {
      const currentUrl = window.location.href;
      const Message = currentUrl;
      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard
          .writeText(Message)
          .then(() => {
            alert(
              "Link copied to clipboard! Paste it on any social media channel and share with your friends."
            );
          })
          .catch((err) => {
            console.error("Failed to copy: ", err);
          });
      } else {
        const textarea = document.createElement("textarea");
        textarea.value = Message;
        textarea.style.position = "fixed";
        document.body.appendChild(textarea);
        textarea.focus();
        textarea.select();
        try {
          document.execCommand("copy");
          alert(
            "Message copied to clipboard! Paste it on any social media channel and share with your friends."
          );
        } catch (err) {
          console.error("Fallback: Oops, unable to copy", err);
        }
        document.body.removeChild(textarea);
      }
    },
    navigateTo(route) {
      this.$router.push(route);
    },
    async fetchBlogDetails() {
      const payload = {
        blogId: this.blogId,
      };

      try {
        const response = await GetBlog(payload);
        if (response.message === "SUCCESS") {
          this.blogData = response.blog;
          this.relatedBlogs = response.relatedBlogs || [];
          this.othercategories = response.blogCategories || [];
          this.setMetaTags();
          this.extractHeadings(response.blog.content);
        } else {
          return this.$refs.toastContainer.addToast(response.message, {
            type: "error",
          });
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          return this.$refs.toastContainer.addToast(
            "Something went wrong. Please try again later.",
            { type: "error" }
          );
        }
      }
    },
    extractHeadings(content) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(content, "text/html");
      const headings = doc.querySelectorAll("h1, h2, h3, h4, h5");

      this.tableOfContents = Array.from(headings).map((heading, index) => {
        const id = `heading-${index}`;

        heading.setAttribute("id", id);

        return {
          id,
          text: heading.textContent,
          level: heading.tagName,
        };
      });

      const modifiedContent = doc.body.innerHTML;
      this.blogData.content = modifiedContent;
    },
    scrollToSection(id) {
      this.$nextTick(() => {
        const section = document.getElementById(id);
        if (section) {
          const offset = 100;
          const sectionPosition =
            section.getBoundingClientRect().top + window.pageYOffset - offset;
          window.scrollTo({
            top: sectionPosition,
            behavior: "smooth",
          });
        } else {
          console.error(`Section with id ${id} not found.`);
        }
      });
    },
  },
};
</script>

<style scoped>
.blog-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 0 auto;
  padding: 20px;
  font-family: "Montserrat-Regular", sans-serif;
  color: #333;
  background: white;
  gap: 2rem;
}
.logged-in {
  margin-left: 18vw;
  padding-top: 100px;
  transition: margin-left 0.3s ease;
}
.logged-in.collapsed {
  margin-left: 60px;
}
.logged-in.notification {
  padding-top: 105px !important;
}
.not-logged-in {
  width: 100%;
}
.blog-header {
  text-align: center;
  margin-bottom: 20px;
  border-radius: 20px;
  background-image: url("./istockphoto-1960523806-612x612.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
}
.blog-header-2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  /* width: 100%; */
  height: 100%;
  background: linear-gradient(90deg, #11365a63, #54535373 50.31%, #ffffff00);
  padding: 2.5rem;
  gap: 2rem;
  color: white;
}
.blog-header h1 {
  font-size: 2.5em;
  margin: 0;
  text-align: left;
}

.blog-meta {
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  font-size: 0.9em;
  color: white;
}

.banner img {
  width: 100%;
  height: auto;
  margin: 20px 0;
  border-radius: 10px;
}
.blog-content {
  text-align: left;
}

.blog-content h1,
.blog-content h2 {
  color: #11365a;
  margin-top: 1.5em;
  font-family: "Montserrat-Regular", sans-serif;
  font-style: normal;
  /* font-weight: 400; */
  line-height: 150%;
  letter-spacing: 0.18px;
}

.blog-content img {
  border-radius: 10px;
}

p {
  line-height: 1.6;
  margin: 1em 0;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.18px;
  text-align: left;
}

pre {
  background-color: #f4f4f4;
  padding: 10px;
  border-left: 3px solid #11365a;
  overflow-x: auto;
}

a {
  color: #1e88e5;
  text-decoration: underline;
}
.routes {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.18px;
  color: #11365a;
  width: 90%;
}
.routes span {
  cursor: pointer;
}
.social-media-share {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  /* margin-top: 30px; */
}
.social-media-share-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 25vh;
  position: sticky;
  top: 120px;
  margin-top: 30vh;
}
.social-media-icons-blogs {
  cursor: pointer;
}
.social-media-icons-blogs {
  width: 30px;
  height: 30px;
}
.tooltip-message-profile {
  display: flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #fff;
}
.blog-content-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  /* align-self: stretch; */
  position: relative;
}
.blog-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: flex-start;
  /* margin: 0 10vw; */
}
.blog-wrappper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-self: stretch;
  gap: 50px;
  width: 90%;
  align-self: center;
}
.blog-wrappper.loginclass {
  flex-direction: column;
  width: 95%;
} 
.related-blogs {
  width: 90%;
  padding: 20px 30px;
  border-radius: 13px;
  /* box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.18), -4px -4px 8px 0px rgba(255, 255, 255, 1); */
  background: #f4f8fa;
}
.carousel-container {
  display: flex;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.carousel-wrapper {
  display: flex;
  gap: 25px;
  padding: 20px 10px;
}

.carousel-card {
  flex: 0 0 auto;
  width: 300px;
  background: white;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s ease;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.18),
    -4px -4px 8px 0px rgba(255, 255, 255, 1);
}

.carousel-card:hover {
  transform: scale(1.05);
}

.carousel-card img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.carousel-card h3 {
  font-size: 18px;
  margin: 10px 0;
  text-align: left;
}

.carousel-card p {
  font-size: 14px;
  color: #555;
}

.read-time {
  font-size: 12px;
  color: #888;
}

.toc-container {
  /* width: 75%; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-radius: 16px;
  background: #f0f7f7;
  padding: 20px 30px;
}
.toc-container.loggedInToc {
  position: inherit !important;
  width: -webkit-fill-available !important;
}

.toc-div {
  width: 130px;
  height: 5px;
  background: #4fabff;
}

.toc-container h3 {
  margin: 0;
  margin-bottom: 10px;
}

.toc {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.toc li {
  margin: 1rem 0;
  text-align: left;
}

.toc a {
  color: #11365a;
  text-decoration: none;
  cursor: pointer;
}

.toc a:hover {
  text-decoration: underline;
}
.tags {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.categories {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 2rem;
  /* padding-left: 20px; */
}
.tags-list {
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: row;
  align-items: center; */
  justify-content: flex-start;
  gap: 15px;
  width: 100%;
}
.tags-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  border: 1px solid gray;
  white-space: nowrap;
  padding: 3px 10px;
  border-radius: 80px;
}
.tag-title {
  text-transform: capitalize;
  font-size: 15px;
}
.btn-comments {
  width: 100%;
  margin-top: 16px;
  padding: 8px 0;
  color: #007562;
  background-color: #ffffff;
  border: 1px solid #007562;
  cursor: pointer;
}
.enter-email-address {
  color: #1e1e1e !important;
  text-align: center;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
  position: relative;
  border-radius: 8px;
  border: 1px solid rgb(199, 199, 199);
  outline: none;
  text-align: left;
  box-shadow: none;
  align-self: stretch;
  background-color: transparent;
  margin: 0;
  height: 35px;
  padding-left: 20px;
}
.title {
  margin: 0;
  text-align: left;
}
.input-wrappper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  align-self: stretch;
  width: 50%;
}
.blog-comment-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  align-self: stretch;
}
.blog-comment-message {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  align-self: stretch;
  width: 100%;
  margin-top: 15px;
}
.blog-comment-message textarea {
  margin: 0;
  align-self: stretch;
  height: 50px;
  border: 1px solid rgb(199, 199, 199);
  box-shadow: none;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
}
.related-blogs h2 {
  text-align: left;
  /* padding-left: 20px; */
}

@media only screen and (max-width: 767px) {
  .blog-container {
    gap: 1rem;
  }
  .blog-wrappper {
    flex-direction: column;
    gap: 25px;
  }
  .toc-container {
    align-self: stretch;
    gap: 0;
    padding: 20px;
  }
  .routes,
  .blog-meta,
  .author {
    font-size: 12px;
  }
  .blog-header-2 {
    padding: 1.5rem;
    gap: 1rem;
  }
  .blog-header h1 {
    font-size: 18px;
  }
  .author {
    margin-top: 0 !important;
  }
  .routes,
  .blog-header,
  .blog-wrappper {
    width: 100% !important;
  }
  .blog-header {
    margin-bottom: 0;
  }
  .toc {
    background-color: transparent;
  }
  .related-blogs {
    padding: 20px;
  }
  .related-blogs h2 {
    margin: 0;
    font-size: 1.25em;
  }
  .carousel-card {
    width: 200px;
  }
  .carousel-card img {
    height: 120px;
  }
  .carousel-wrapper {
    gap: 15px;
    padding: 20px 0px;
  }
  .tag-title {
    font-size: 12px;
  }
  .fa-tag::before {
    font-size: 12px;
  }
  .tags-wrapper {
    gap: 5px;
    padding: 0px 6px;
  }
  .tags-list {
    gap: 5px;
  }
  .carousel-card h3 {
    font-size: 16px;
  }
  .categories {
    gap: 10px;
  }
  .blog-body {
    margin: 0 !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1156px) {
  .blog-wrappper {
    flex-direction: column;
    gap: 25px;
  }
  .toc-container {
    align-self: stretch;
  }
}
@media only screen and (min-width: 1156px) {
}
</style>
