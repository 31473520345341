<template>
  <div class="popup bg-b-lue">
    <div class="loader-wrapper" v-if="cartLoader">
      <LoaderAnimation />
      <div
        style="
          font-size: 14px;
          margin-top: 30px;
          color: #2e5ba7;
          font-weight: 600;
        "
      >
        Please wait <br />
        Your orders are being processed...
      </div>
    </div>
    <div class="frame-35628" v-else>
      <div class="title-wrapper">
        <div class="title">Order Delivery Email Address</div>
        <i class="fa-solid fa-xmark fa-xl" @click="close()"></i>
      </div>
      <div
        v-if="this.$root.flag"
        class="validity"
        :style="{
          background: this.$root.warning === 'EMPTY' ? '#861f1f' : '#F0AD4E',
        }"
      >
        <div
          class="validity-title1"
          :style="{
            color: this.$root.warning === 'EMPTY' ? 'white' : '#2c3e50',
          }"
        >
          {{this.$root.warning === 'EMPTY' ? 'Insufficient' : 'Low'}} Credits. Please recharge
        </div>
        <button
          class="warning-action"
          :style="{
            border:
              this.$root.warning === 'EMPTY'
                ? '1.5px solid #fff'
                : '1.5px solid #2c3e50',
            color: this.$root.warning === 'EMPTY' ? 'white' : '#2c3e50',
          }"
          @click="redirectToCredits"
        >
          Credits
        </button>
      </div>
      <div class="user-details-wrapper" style="width: 100%">
        <div
          class="input-group1"
          style="gap: 10px; justify-content: space-between; border-radius: 13px"
        >
          <div class="input-field-title">
            Deliver to <span style="color: red">*</span>
          </div>
          <input
            style="width: auto"
            type="text"
            v-model="deliveryEmail"
            @change="createButtonDisabled ? none : toggleConfirmDeliveryEmail"
            :placeholder="
              deliveryEmail == null
                ? 'Enter Delivery Email'
                : `${deliveryEmail}`
            "
            required
          />
        </div>
        <div
          class="input-group1"
          style="gap: 10px; justify-content: space-between; border-radius: 13px"
        >
          <div class="input-field-title">
            Confirm Email <span style="color: red">*</span>
          </div>
          <input
            :class="{ shake: shakeConfirmEmail }"
            :style="{
              width: 'auto',
              border: toggleConfirmDeliveryEmailBorder,
            }"
            type="text"
            v-model="confirmDeliveryEmail"
            @change="createButtonDisabled ? none : toggleConfirmDeliveryEmail"
            placeholder="Re-enter Delivery Email"
            required
          />
        </div>
      </div>
      <div class="invitees-wrapper">
        <div
          :class="[
            'invitees-list',
            !userDetails?.includes('POSTPAID_ORDERS')
              ? 'invitees-list-height2'
              : 'invitees-list-height',
          ]"
        >
          <div class="table-wrapper">
            <table id="searchResultTable">
              <thead style="background: #ecf4ff">
                <tr>
                  <th v-for="col in tableColumns" :key="col">
                    {{ col }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="cartItem in cartItemsList"
                  :style="hoveredRow2 === index ? hoverStyle : {}"
                  :key="cartItem?.id"
                >
                  <td>
                    {{ cartItem?.order?.subjectName }}
                  </td>
                  <td>
                    {{
                      cartItem?.order?.reportType === "FRESH"
                        ? "Fresh Report"
                        : `Report as at ${cartItem?.order?.reportDetails?.report_dated} (${cartItem?.order?.reportDetails?.report_label})`
                    }}
                  </td>
                  <td v-if="!userDetails?.includes('POSTPAID_ORDERS')">
                    USD {{ cartItem?.order?.price }}
                  </td>
                  <td>
                    <i
                      class="fa-solid fa-xmark"
                      @click="deleteFromCart(cartItem, false)"
                    ></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          class="order-total"
          v-if="!userDetails?.includes('POSTPAID_ORDERS')"
        >
          <div class="order-total-currency-wrapper">
            <div class="currency">Currency</div>
            <select v-model="selectedCurrency" class="currency-dropdown">
              <option
                v-for="currency in currencies"
                :value="currency"
                :key="currency.code"
                class="country-option"
              >
                {{ currency.code.toUpperCase() }} - {{ currency.name }}
              </option>
            </select>
          </div>
          <div
            class="order-total-currency-wrapper"
            v-if="availableDiscount > 0"
          >
            <div class="currency">Order Total for all reports</div>
            <div
              class="order-amount"
              :style="{
                textDecorationLine: isDiscountApplied ? 'line-through' : none,
                color: isDiscountApplied ? '#AD0E37' : '#11365A',
              }"
            >
              {{ selectedCurrency.code.toUpperCase() }}
              {{
                convertedTotal(totlaValue?.currencyAmount)?.toFixed(
                  selectedCurrency.code === "usd" ? 0 : 2
                )
              }}
            </div>
          </div>
          <div
            class="order-total-currency-wrapper extra"
            v-if="availableDiscount > 0"
            style="
              border-radius: 16px;
              border: 1px solid #11365a;
              background: #d4f1f3;
              padding: 10px 16px;
            "
          >
            <div class="currency available-discounts">
              <span v-if="!isDiscountApplied"
                >Apply cash credits on this order?<i>*</i></span
              >
              <span v-else>Cash Credits used<i>*</i></span>
            </div>
            <div class="available-discounts">
              <div class="discount-radio-buttons" v-if="availableDiscount > 0">
                <label>
                  <input
                    type="radio"
                    v-model="isDiscountApplied"
                    :value="true"
                    @change="applyDiscount(true)"
                  />
                  Yes
                </label>
                <label>
                  <input
                    type="radio"
                    v-model="isDiscountApplied"
                    :value="false"
                    @change="applyDiscount(false)"
                  />
                  No
                </label>
              </div>
              <div class="order-amount" style="font-weight: 500; width: 90px">
                {{ isDiscountApplied ? "-" : ""
                }}{{ selectedCurrency.code.toUpperCase() }}
                {{
                  convertedTotal(
                    isDiscountApplied ? availableDiscount : availableDiscount
                  )?.toFixed(selectedCurrency.code === "usd" ? 0 : 2)
                }}
              </div>
            </div>
          </div>
          <div class="order-total-currency-wrapper">
            <div class="currency" style="font-weight: 700">Net Total</div>
            <div class="order-amount">
              {{ selectedCurrency.code.toUpperCase() }}
              {{
                convertedTotal(
                  isDiscountApplied
                    ? discountedValue
                    : totlaValue?.currencyAmount
                )?.toFixed(selectedCurrency.code === "usd" ? 0 : 2)
              }}
            </div>
          </div>
          <div
            class="order-total-currency-wrapper"
            v-if="availableDiscount > 0"
          >
            <div class="max-credits-applicable">
              *(Max credits applicable on this order)
            </div>
          </div>
        </div>
        <div class="buttons">
          <div class="cancel-button" @click="close()">
            <span>Cancel</span>
          </div>
          <div
            class="place-order-button"
            :style="{
              backgroundColor: createButtonColor,
            }"
            :disabled="createButtonDisabled"
            @click="createButtonDisabled ? shakeConfirmDelivery() : checkout()"
          >
            <span :style="{ color: createButtonTextColor }">Place Order</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Toastify ref="toastContainer" />
</template>
<script>
//   import VueCookies from "vue-cookies";
import Toastify from "@/components/Toastify.vue";
import { GetCartItems, AllCurrencies, DeleteFromCart } from "@/api/APIs";
import { mapActions, mapGetters } from "vuex";
import VueCookies from "vue-cookies";
import LoaderAnimation from "@/assets/Animation/LoaderAnimation.vue";

export default {
  name: "CartCheckout",
  components: {
    Toastify,
    LoaderAnimation,
  },
  props: {
    cartLoader: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      tableColumns: [],
      cartItemsList: [],
      shakeConfirmEmail: false,
      deliveryEmail: VueCookies.get("email"),
      confirmDeliveryEmail: "",
      currencyValue: null,
      currencies: [],
      selectedCurrency: {
        id: 1,
        name: "US Dollar",
        code: "usd",
      },
      totlaValue: null,
      discountedValue: null,
      availableDiscount: null,
      isDiscountApplied: false,
    };
  },
  mounted() {
    this.GetCartItems();
    this.tableColumns = this.getTableColumns();
    if (!this.userDetails?.includes("POSTPAID_ORDERS")) {
      this.fetchCurrenciesList();
      this.fetchAPIData();
    }
  },
  computed: {
    ...mapGetters(["userDetails"]),
    convertedRate() {
      const orderTotalUSD = this.getOrderTotal();

      if (this.selectedCurrency.code === "usd") {
        return orderTotalUSD;
      } else {
        const conversionRate = this.currencyValue[this.selectedCurrency.code];
        return conversionRate;
      }
    },
    createButtonDisabled() {
      return !(
        this.cartItemsList.length !== 0 &&
        this.isValidEmail(this.deliveryEmail) &&
        this.isValidEmail(this.confirmDeliveryEmail) &&
        this.$root.flag !== true
      );
    },
    toggleConfirmDeliveryEmail() {
      return (
        this.isValidEmail(this.deliveryEmail) ==
        this.isValidEmail(this.confirmDeliveryEmail)
      );
    },
    createButtonColor() {
      return this.createButtonDisabled ? "#a2dfe3" : "#26BBC2";
    },
    createButtonTextColor() {
      return this.createButtonDisabled ? "#969da7" : "#04162D";
    },
    toggleConfirmDeliveryEmailBorder() {
      return this.toggleConfirmDeliveryEmail
        ? "1px solid #57575780"
        : "1px solid #D20D3B";
    },
  },
  methods: {
    ...mapActions(["updateCartCount"]),
    redirectToCredits() {
      this.close();
      this.$router.push({ path: "/profile", query: { tab: "credits" } });
    },
    getTableColumns() {
      if (this.userDetails?.includes("POSTPAID_ORDERS")) {
        if (window.innerWidth <= 767) {
          return ["Company Name", "Report Type", ""];
        } else {
          return ["Company Name", "Report Type", "Action"];
        }
      } else {
        if (window.innerWidth <= 767) {
          return ["Company Name", "Report Type", "Price", ""];
        } else {
          return ["Company Name", "Report Type", "Price", "Action"];
        }
      }
    },
    async deleteFromCart(item, clearCart) {
      const payload = clearCart
        ? { clearCart: clearCart }
        : { itemId: item.id };
      try {
        const response = await DeleteFromCart(payload);
        if (response.message === "Deleted from cart") {
          this.GetCartItems();
          if (this.$root.drawerOpen) {
            this.$root.GetCartItems();
          }
        } else if (response.message === "Cart cleared") {
          this.GetCartItems();
        } else {
          return this.$refs.toastContainer.addToast(response.message, {
            type: "error",
          });
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          return this.$refs.toastContainer.addToast(
            "Something went wrong. Please try again later.",
            { type: "error" }
          );
        }
      }
    },
    async fetchAPIData() {
      try {
        const response = await fetch(
          "https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/usd.json"
        );
        if (response.ok) {
          const data = await response.json();
          this.currencyValue = data.usd;
        } else {
          console.error("Failed to fetch API data:", response.statusText);
        }
      } catch (error) {
        console.error("Error occurred while fetching API data:", error);
      }
    },
    convertedTotal(amount) {
      if (this.selectedCurrency.code === "usd") {
        return amount;
      } else {
        const conversionRate = this.currencyValue[this.selectedCurrency.code];
        return amount * conversionRate;
      }
    },
    applyDiscount(value) {
      this.isDiscountApplied = value;
    },
    async fetchCurrenciesList() {
      try {
        const response = await AllCurrencies();
        this.currencies = await response.allCurrencies;
      } catch (error) {
        this.loginError = "Error occurred during login.";
        console.error(error);
      }
    },
    close() {
      this.$emit("close");
    },
    checkout() {
      this.$emit("placeorder", this.deliveryEmail, this.isDiscountApplied);
    },

    shakeConfirmDelivery() {
      if (this.deliveryEmail && !this.confirmDeliveryEmail) {
        this.shakeConfirmEmail = true;
        setTimeout(() => {
          this.shakeConfirmEmail = false;
        }, 500);
        return;
      }
    },
    isValidEmail(email) {
      return email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    },
    async GetCartItems() {
      try {
        const response = await GetCartItems();
        if (response.cart) {
          this.cartItemsList = response.cart;
          if (!this.userDetails?.includes("POSTPAID_ORDERS")) {
            this.totlaValue = response.totalValue;
            this.availableDiscount = response.discountAvailable;
            this.discountedValue = response.discountedValue;
          }
          this.updateCartCount(response?.size);
          this.$root.flag = response.insufficientCreditsFlag;
          if (response.lowCreditBalanceFlag)
            this.$store.dispatch("setWarning", response.lowCreditBalanceFlag);
        } else {
          return this.$refs.toastContainer.addToast(response.message, {
            type: "error",
          });
        }
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          return this.$refs.toastContainer.addToast(
            "Something went wrong. Please try again later.",
            { type: "error" }
          );
        }
      }
    },
  },
};
</script>
<style scoped>
.bg-b-lue {
  background: rgba(0, 0, 0, 8%);
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
  z-index: 9999;
}
.frame-35628 {
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: center;
  justify-content: flex-start;
  width: 60vw;
  position: relative;
  background: #ffffff;
  border-radius: 24px;
  min-height: 384px;
  box-shadow: 0px 24px 24px 0px rgba(17, 54, 90, 0.2);
  padding: 2rem 3rem;
}
.title-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.title {
  color: var(--Dark-Blue, #11365a);
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  align-self: stretch;
  text-align: left;
}
.invitees-wrapper,
.invitees-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: stretch;
  gap: 18px;
}
.invitees-list-height {
  height: 45vh;
  overflow-y: auto;
  overflow-x: hidden;
  background: #f2f6fd;
  padding: 10px;
  border-radius: 16px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.invitees-list-height2 {
  height: 32vh;
  overflow-y: auto;
  overflow-x: hidden;
  background: #f2f6fd;
  padding: 10px;
  border-radius: 16px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.discount-radio-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.discount-radio-buttons input[type="radio"] {
  accent-color: #11365a;
  width: 15px;
  height: 15px;
}
.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  align-self: stretch;
  padding: 0;
  box-shadow: none;
}
.cancel-button {
  color: #11365a;
  border: 2px solid #11365a;
  font-family: "Montserrat-Regular", sans-serif;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.16px;
  border-radius: 16px;
  padding: 8px 22px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  align-self: stretch;
  width: 50%;
}
.place-order-button {
  font-family: "Montserrat-Regular", sans-serif;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.16px;
  border-radius: 16px;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  align-self: stretch;
  width: 50%;
}
.table-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* margin: 20px 0; */
}
#searchResultTable {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
  border-radius: 16px;
  overflow: hidden;
  font-family: "Montserrat-Regular", sans-serif;
}
#searchResultTable th {
  text-align: left;
  font-family: "Montserrat-Regular", sans-serif;
  color: #11365a;
  padding: 10px 16px;
  min-width: 30px;
}
#searchResultTable td {
  text-align: left;
  padding: 10px 16px;
  color: #11365a;
  font-size: 16px;
  font-weight: 400;
  line-height: 175%;
  letter-spacing: -0.16px;
}
.no-invitees {
  align-self: stretch;
  color: #11365a5d;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  text-align: left;
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.user-details-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.input-group1 {
  display: flex;
  flex-direction: column;
  width: 49%;
  box-sizing: border-box;
}
.input-group1 input {
  padding: 10px;
  border: 1px solid rgba(87, 87, 87, 0.5);
  border-radius: 13px;
  height: 25px !important;
  box-shadow: none;
  margin: 0;
  width: -webkit-fill-available;
  outline: none;
  font-size: 16px;
}
.input-field-title {
  font-size: 16px;
  font-weight: 600;
  font-family: "Montserrat-Medium", sans-serif;
  text-align: left;
  width: 100%;
}
.loader-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.order-total {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.order-total-currency-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  padding: 0 16px;
}
.max-credits-applicable {
  color: var(--Text-Dark, #04162d);
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.17px;
}
.currency-dropdown {
  width: 22% !important;
  outline: none;
  border: 1px solid rgba(87, 87, 87, 0.5);
  border-radius: 10px;
  padding: 0 10px;
  height: 27.22px;
}
.currency {
  color: var(--Text-Dark, #04162d);
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.2px;
}
.order-amount {
  color: var(--Text-Dark, #04162d);
  text-align: right;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.2px;
}
.apply-button {
  display: flex;
  padding: 5px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: #11365a;
  color: #fff;
  text-align: center;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.16px;
  cursor: pointer;
}
.available-discounts {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}
.validity {
  display: flex;
  padding: 12px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  border-radius: 13px;
  background: var(--Danger-Light-BG, #ffc9c9);
}
.validity-title1 {
  color: var(--Danger-Text, #861f1f);
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-align: left;
}
.warning-action {
  border-radius: 10px;
  background: transparent;
  cursor: pointer;
}
@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}

.shake {
  animation: shake 0.5s;
}
@media only screen and (max-width: 767px) {
  .frame-35628 {
    width: 85vw;
    padding: 1.5rem 1rem;
    gap: 15px;
  }
  .title {
    font-size: 18px;
  }
  .invitees-list-height {
    height: 30vh;
  }
  .invitees-list-height2 {
    height: 24vh;
    padding: 5px;
  }
  .add-button {
    padding: 6px 24px;
  }
  .create-button {
    padding: 8px 24px;
  }
  .cancel-button {
    padding: 6px 24px;
  }
  .input-group1 {
    width: 100% !important;
  }
  .input-group1 input {
    height: 16px !important;
    font-size: 14px;
  }
  .user-details-wrapper {
    flex-direction: column;
    gap: 10px;
  }
  #searchResultTable th,
  #searchResultTable td {
    font-size: 13px;
    padding: 5px 5px;
    line-height: 132%;
  }
  #searchResultTable th {
    min-width: 14px;
  }
  .currency,
  .order-amount {
    font-size: 14px;
  }
  .cancel-button {
    align-self: auto;
  }
  .place-order-button {
    align-self: auto;
    padding: 8px 24px;
  }
  .extra {
    flex-direction: column;
    align-self: stretch;
    align-items: flex-start;
    gap: 5px;
  }
  .available-discounts {
    width: 100%;
    justify-content: space-between;
  }
  .input-field-title {
    font-size: 14px;
  }
  .order-total {
    gap: 4px;
  }
  .invitees-wrapper {
    gap: 15px;
  }
  .max-credits-applicable {
    font-size: 12px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1156px) {
  .input-group1 input {
    padding: 5px;
  }
}
@media only screen and (min-width: 1156px) {
  .invitee-email {
    width: 40%;
  }
  .add-button {
    width: 18%;
  }
}
</style>
