<template>
  <div
    :class="[
      'table-container',
      {
        'logged-in': !isMobile,
        'not-logged-in': isMobile,
        collapsed: this.$root.isCollapsed && !isMobile,
        notification:
          this.$root.warning && this.$root.warning !== 'NORMAL' && !isMobile,
      },
    ]"
  >
    <div class="frame-35732">
      <div class="frame-357322">
        <div class="sub-frame-357322">
          <div class="current-credits">
            <div class="credits-wrapper">
              <div class="title">Available Cash Credits</div>
              <div class="balance-amount">USD {{ credits }}</div>
            </div>
            <div
              class="credits-wrapper"
              style="justify-content: flex-start; gap: 15px"
            >
              <div class="referralcode-wrapper">
                <div class="title" style="font-weight: 500; font-size: 16px">
                  Referral Code
                </div>
                <div class="refer" @click="this.$root.toggleTooltip()">
                  <span>
                    {{ referralCode }}
                  </span>
                </div>
              </div>
              <div class="tooltip-content-profile">
                <div
                  v-for="(option, index) in this.$root.shareOptions"
                  :key="index"
                  class="tooltip-message-profile"
                  @click="this.$root.handleShare(option.type)"
                >
                  <img
                    :src="this.$root.getCDNlink(option.icon)"
                    :alt="`Share referral link on ${option.label} - Credencedata`"
                    class="social-media-icons"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="sub-frame-357322">
            <!-- <div class="earned-rewards-wrapper">
            <div class="title2">Earned Rewards</div>
            <div class="earned-rewards-list">
              <div
                class="reward-card"
                v-for="(reward, index) in rewards.slice(0, 2)"
                :key="index"
              >
                <div class="reward-title-wrapper">
                  <div class="reward-title1">{{ reward?.title }}</div>
                  <div class="reward-title2">{{ reward?.description }}</div>
                </div>
                <div class="claim-reward">Claim Reward</div>
              </div>
            </div>
            <div class="view-all" v-if="rewards.length > 2">View All</div>
          </div> -->
            <div class="sent-invites-wrapper">
              <div
                class="sent-invites-title"
                style="
                  background: #d5f1f3;
                  border-top-left-radius: 16px;
                  border-top-right-radius: 16px;
                "
              >
                <div class="wrapper-1">
                  <div class="referral-title-0">
                    Earn {{ this.$root.referralCredits }} when you invite a
                    friend
                  </div>
                  <div class="referral-title-01">
                    Invite your friends to join Credence and both of you earn
                    {{ this.$root.referralCredits }} when they place their first
                    order.
                  </div>
                </div>
                <div
                  class="share-invitation-main"
                  v-if="this.$root.myReferralList.length > 0"
                  @click="this.$root.toggleInviteNewUser"
                >
                  Invite Friend
                </div>
              </div>
              <div class="sentinvites-wrapper">
                <div class="sent-invites-title">
                  <div
                    class="referral-title-2"
                    style="font-weight: 600; font-size: 18px"
                  >
                    Sent Invites
                  </div>
                </div>
                <ul
                  class="cart-items-wrapper-rewards"
                  :style="{
                    alignItems:
                      this.$root.myReferralList.length === 0
                        ? 'center'
                        : 'flex-start',
                    justifyContent:
                      this.$root.myReferralList.length === 0
                        ? 'center'
                        : 'flex-start',
                    // padding: '10px',
                    // background: '#f2f6fd',
                    borderRadius: '10px',
                    marginTop: 0,
                  }"
                >
                  <li
                    class="cart-items"
                    style="
                      border: none;
                      box-shadow: none;
                      padding: 5px 0;
                      background: transparent;
                    "
                    v-for="item in this.$root.myReferralList"
                    :key="item.id"
                  >
                    <div
                      class="info-wrapper-nav"
                      style="
                        flex-direction: row;
                        justify-content: space-between;
                      "
                    >
                      <div class="referred-email-main">
                        {{ item?.referredEmail }}
                      </div>
                      <div
                        class="invitationstatus-main"
                        :style="{
                          background:
                            item?.status !== 'REF_SENT' ? '#c9ffc9' : '#E7EBEE',
                          cursor: 'default',
                        }"
                      >
                        <i
                          class="fa-regular fa-circle-check"
                          style="color: #0d630d"
                          v-if="item?.status === 'USER_CREATED'"
                        ></i>
                        <div
                          class="invitation-status-main"
                          :style="{
                            color:
                              item?.status === 'REF_SENT'
                                ? '#11365A'
                                : '#0D630D',
                          }"
                        >
                          {{
                            item?.status === "REF_SENT" ? "Invited" : "Accepted"
                          }}
                        </div>
                      </div>
                    </div>
                  </li>
                  <li
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                    "
                    v-if="this.$root.myReferralList.length === 0"
                  >
                    <div class="cart-item-data" style="justify-content: center">
                      No recent invites to show!
                    </div>
                    <div class="cart-item-data" style="justify-content: center">
                      Invite more people to get invitation bonus.
                    </div>
                    <div
                      class="share-invitation-main"
                      style="
                        margin-top: 15px;
                        background: #fcd600;
                        border: none;
                        cursor: pointer;
                        font-weight: 600;
                      "
                      v-if="this.$root.myReferralList.length === 0"
                      @click="this.$root.toggleInviteNewUser()"
                    >
                      Invite Friend
                    </div>
                  </li>
                </ul>
              </div>
              <!-- <div class="view-all" v-if="this.$root.myReferralList.length > 4">
                View All
              </div> -->
            </div>
          </div>
        </div>
        <div class="faqs-section">
          <div class="faqs-title">FAQs</div>
          <div class="general-faq">
            <div
              class="faq-subtitle-wrapper"
              @click="toggleFaqCategory('general')"
            >
              <div class="faq-subtitle">General Questions</div>
              <i
                :class="[
                  'fa-solid',
                  selectedFaqCategory === 'general' ? 'fa-minus' : 'fa-plus',
                  'fa-lg',
                ]"
                style="color: #11365a"
              ></i>
            </div>
            <div class="faq-list" v-if="selectedFaqCategory === 'general'">
              <div v-for="faq in generalfaqs" :key="faq.id" class="faq-wrapper">
                <div class="faq-info-wrapper" @click="toggleFaq(faq.id)">
                  <div class="faq-title">{{ faq.title }}</div>
                  <i
                    :class="[
                      'fa-solid fa-angle-down',
                      { 'rotate-icon': selectedFaq === faq.id },
                    ]"
                    style="color: #11365a"
                  ></i>
                </div>
                <div
                  v-if="selectedFaq === faq.id"
                  class="faq-description"
                  v-html="faq.description"
                  ref="faqDescription"
                ></div>
              </div>
            </div>
          </div>

          <!-- Earning Cash Rewards  -->
          <div class="general-faq">
            <div
              class="faq-subtitle-wrapper"
              @click="toggleFaqCategory('cashRewards')"
            >
              <div class="faq-subtitle">Earning Cash Rewards</div>
              <i
                :class="[
                  'fa-solid',
                  selectedFaqCategory === 'cashRewards'
                    ? 'fa-minus'
                    : 'fa-plus',
                  'fa-lg',
                ]"
                style="color: #11365a"
              ></i>
            </div>
            <div class="faq-list" v-if="selectedFaqCategory === 'cashRewards'">
              <div
                v-for="faq in cashRewardsFaqs"
                :key="faq.id"
                class="faq-wrapper"
              >
                <div class="faq-info-wrapper" @click="toggleFaq(faq.id)">
                  <div class="faq-title">{{ faq.title }}</div>
                  <i
                    :class="[
                      'fa-solid fa-angle-down',
                      { 'rotate-icon': selectedFaq === faq.id },
                    ]"
                    style="color: #11365a"
                  ></i>
                </div>
                <div v-if="selectedFaq === faq.id" class="faq-description">
                  {{ faq.description }}
                </div>
              </div>
            </div>
          </div>

          <!-- Redeeming Cash Rewards  -->
          <div class="general-faq">
            <div
              class="faq-subtitle-wrapper"
              @click="toggleFaqCategory('redeemRewards')"
            >
              <div class="faq-subtitle">Redeeming Cash Rewards</div>
              <i
                :class="[
                  'fa-solid',
                  selectedFaqCategory === 'redeemRewards'
                    ? 'fa-minus'
                    : 'fa-plus',
                  'fa-lg',
                ]"
                style="color: #11365a"
              ></i>
            </div>
            <div
              class="faq-list"
              v-if="selectedFaqCategory === 'redeemRewards'"
            >
              <div
                v-for="faq in redeemRewardsFaqs"
                :key="faq.id"
                class="faq-wrapper"
              >
                <div class="faq-info-wrapper" @click="toggleFaq(faq.id)">
                  <div class="faq-title">{{ faq.title }}</div>
                  <i
                    :class="[
                      'fa-solid fa-angle-down',
                      { 'rotate-icon': selectedFaq === faq.id },
                    ]"
                    style="color: #11365a"
                  ></i>
                </div>
                <div
                  v-if="selectedFaq === faq.id"
                  class="faq-description"
                  v-html="faq.description"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Toastify ref="toastContainer" />
</template>
<script>
// import VueCookies from "vue-cookies";
import Toastify from "@/components/Toastify.vue";
// import { Dropdown } from "floating-vue";
import "floating-vue/dist/style.css";
import rewardIcon from "@/assets/Icons/reward1.webp";
import { UserDetails } from "@/api/APIs";
import VueCookies from "vue-cookies";

export default {
  name: "RewardsAndDiscounts",
  components: {
    // Dropdown,
    Toastify,
  },
  data() {
    return {
      selectedResult: null,
      isSafari: false,
      hoveredRow: null,
      hoveredRow1: null,
      repositoryTabelColumns: ["Company Name", "Country", "Actions"],
      isMobile: window.innerWidth < 1023,
      mobile: window.innerWidth <= 767,
      selectedFaqCategory: "general",
      selectedFaq: null,
      generalfaqs: [
        {
          id: 1,
          title: "What is the Credence Connect Referral Program?",
          description: `<p style="margin: 0;">The Credence Connect Program allows you to earn cash reward points by inviting others to use our business intelligence platform. Both the Referrer (you) and the Referee (the person you invite) can earn rewards when the Referee signs up and places their first order using your unique referral code or invitation link.</p>`,
        },
        {
          id: 2,
          title:
            "Who is eligible for earning cash reward points under the Credence Connect program ?",
          description: `<p style="margin: 0;">All of our registered customers are eligible for earning cash reward points under the Credence Connect program.</p>`,
        },
        {
          id: 3,
          title: "How do I get my unique referral code?",
          description: `<p style="margin: 0;">You can join the referral program by registering on our platform <a href="https://www.credencedata.com" target="_blank" rel="noopener noreferrer">www.credencedata.com</a> . Once you log in,  you can go to your user profile by clicking on your name in the left-bottom corner of the screen. There you will find a referral invite link and referral code.</p>`,
        },
        {
          id: 4,
          title: "How can I invite others to join Credence?",
          description: `<p style="font-weight: 600; margin: 0;">You can invite others in two ways:</p><ul style="list-style-type: number; margin: 0;">
      <li><strong style="font-weight: 600;">Copy and Share:</strong> <span style="font-weight: 500;">Copy your referral code and share it via email, social media, or any other platform. The user can sign up using that code to get benefits.</span></li>
      <li><strong style="font-weight: 600;">Direct Invite:</strong> <span style="font-weight: 500;">Use the system to send an invitation directly by entering the email address of the person you want to invite.</span></li>
    </ul>`,
        },
        {
          id: 5,
          title:
            "Is there a difference in rewards between the two invitation methods?",
          description: `No, there is no difference. Whether you share your referral code directly or invite someone through the system, the rewards remain the same. However if you invite someone through the “<span style="font-weight: 600;">Invite a friend</span>” link, you will be able to track the referral status and rewards earned through our platform.`,
        },
      ],
      cashRewardsFaqs: [
        {
          id: 6,
          title: "How do I earn cash reward points as a Referrer?",
          description:
            "You earn cash reward points when the person you invite (the Referee) places their first order on the platform.",
        },
        {
          id: 7,
          title: "How does the Referee earn cash rewards points?",
          description:
            "The Referee earns cash reward points when they sign up using your referral code, which they can apply to their future orders. The same reward is shared with you (the Referrer) when your friend (the Referee) places their first order.",
        },
        {
          id: 8,
          title: "Are there any rewards given when I send an invite?",
          description:
            "No rewards are given just for sending an invite. Rewards are earned when the Referee signs up and places their first order.",
        },
      ],
      redeemRewardsFaqs: [
        {
          id: 9,
          title: "How can I redeem my cash reward points?",
          description: `<p style="margin: 0;">Cash reward points can be redeemed when placing an order for the same dollar value. However, the maximum amount of cash reward points that can be applied to a single order is either USD 100 equivalent points or 40% of the order value, whichever is lesser.<p>`,
        },
        {
          id: 10,
          title:
            "Can I use my cash reward points with other promotional offers?",
          description: `<p style="margin: 0;">Yes, you can combine your referral rewards with other cash rewards from promotional offers when applicable.</p>`,
        },
        {
          id: 11,
          title: "Do cash reward points expire?",
          description: `<p style="margin: 0;">Cash reward points will remain in your account as long as it is active.</p>`,
        },
        {
          id: 12,
          title: "Can I earn rewards for multiple referrals?",
          description: `<p style="margin: 0;">Yes, you can earn cash reward points for each successful referral who signs up and places their first order.</p>`,
        },
        {
          id: 13,
          title: "Will the reward amounts change?",
          description: `<p style="margin: 0;">The reward amounts applicable for future sign ups are at the discretion of our company and can be amended in the future based on the performance of the program but the rewards earned in the past will remain in your wallet and can be used against future orders.</p>`,
        },
        {
          id: 14,
          title:
            "Can I redeem cash reward points for anything other than purchases on Credence Platform?",
          description: `<p style="margin: 0;">No, cash reward points can only be redeemed for purchases made on our platform. They cannot be exchanged for cash or used for any other purpose.</p>`,
        },
        {
          id: 15,
          title: "Where can I track the status of my referrals?",
          description: `<p style="margin: 0;"> You can click on the rewards <img
                  src= ${rewardIcon}
                  alt="Credence My Orders"
                  class="icon-image"
                /> icon from the Top navigation menu and visit your referral dashboard. <p>`,
        },
      ],
      rewards: [
        {
          title: "Buy 3 reports and get 10%",
          description: "Expires on 04th Aug 2025",
        },
        {
          title: "Buy 3 reports and get 10%",
          description: "Expires on 28th Sept 2024",
        },
        {
          title: "Flat 10% off",
          description: "Expires on 30th Sept 2024",
        },
      ],
    };
  },
  computed: {
    credits() {
      return this.$store.getters.credits;
    },
    referralCode() {
      return this.$store.getters.referralCode;
    },
    hoverStyle() {
      return this.isSafari
        ? {
            borderRadius: "13px",
            background: "#11365a1f",
          }
        : {
            borderRadius: "13px",
            background:
              // "#f2f2f2",
              "linear-gradient(133deg, rgba(38, 187, 194, 0.2) 27.88%, rgba(24, 207, 215, 0) 79.91%), #3673ae14",
          };
    },
  },
  created() {
    this.UserDetails();
  },
  mounted() {
    this.$root.MyReferrals();
  },
  beforeUnmount() {},
  methods: {
    async UserDetails() {
      const response = await UserDetails(
        VueCookies.get("email"),
        VueCookies.get("token")
      );
      if (response) {
        this.$store.dispatch("setCredits", response.availableCredits);
      }
    },
    toggleFaqCategory(category) {
      this.selectedFaqCategory =
        this.selectedFaqCategory === category ? "general" : category;
      this.selectedFaq = null;
    },
    toggleFaq(faqId) {
      this.selectedFaq = this.selectedFaq === faqId ? null : faqId;
    },
  },
};
</script>
<style scoped>
.logged-in {
  margin-left: 18vw;
  padding-top: 55px !important;
  transition: margin-left 0.3s ease;
}
.logged-in.collapsed {
  margin-left: 60px;
}
.logged-in.notification {
  padding-top: 105px !important;
}
.not-logged-in {
  width: 100%;
}
.table-container,
.table-container * {
  box-sizing: border-box;
}
.table-container {
  padding-top: 4rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  /* margin-left: 18vw; */
}
.frame-35732 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 2rem 3rem;
}
.frame-357322 {
  display: flex;
  width: 100%;
  height: 100%;
  /* padding: 30px; */
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex-shrink: 0;
}
.sub-frame-357322 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
}
.current-credits {
  display: flex;
  flex-direction: column;
  height: 132px;
  padding: 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 16px;
  background: linear-gradient(331deg, #11365a 6.49%, #2473c0 115.04%);
  box-shadow: 6px 8px 15px 2px rgba(17, 54, 90, 0.2);
}
.title {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.2px;
}
.balance-amount {
  color: #fcd600;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.2px;
}
.earned-rewards-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}
.title2 {
  color: #11365a;
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.24px;
}
.earned-rewards-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}
.reward-card {
  display: flex;
  padding: 24px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid rgba(24, 195, 223, 0.2);
  background: #fff;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
}
.reward-title1 {
  color: #11365a;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.18px;
  align-self: stretch;
  text-align: left;
}
.reward-title2 {
  color: var(--Text-Mid, #3e4855);
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.16px;
}
.claim-reward {
  display: flex;
  height: 52px;
  padding: 13px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 13px;
  background: var(--Other-Yellow, #fcd600);
  color: #11365a;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.14px;
  text-transform: capitalize;
  cursor: pointer;
}
.reward-title-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
}
.view-all {
  color: var(--Dark-Blue, #11365a);
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.18px;
  text-decoration-line: underline;
  align-self: stretch;
  cursor: pointer;
}
.sent-invites-wrapper {
  display: flex;
  padding-bottom: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  /* flex: 1 0 0; */
  align-self: stretch;
  border-radius: 16px;
  background: #fff;
  /* height: 43vh; */
}
.sentinvites-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 35vh;
  overflow-y: scroll;
  overflow-x: hidden;
  gap: 15px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.sent-invites-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  font-family: "Montserrat-Medium", sans-serif;
  /* cursor: pointer; */
  align-self: stretch;
  justify-content: space-between;
  padding: 16px 16px 0 16px;
}
.referral-title-2 {
  color: var(--Secondary-Grey-900, #2b3674);
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.referral-title-0 {
  color: #11365a;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.2px;
  text-align: justify;
}
.invite {
  font-family: "Montserrat-Medium", sans-serif;
  font-style: normal;
  cursor: pointer;
  text-decoration: underline;
}
.referral-title-01 {
  color: var(--Text-Mid, #3e4855);
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.16px;
  text-align: justify;
  margin-bottom: 10px;
}
.tooltip-content-profile {
  width: auto;
  flex-direction: row;
  gap: 10px;
}
.tooltip-message-profile {
  display: flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #fff;
}
.wrapper-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
}
.share-invitation-main {
  display: flex;
  padding: 5px 15px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 13px;
  border: 2px solid #11365a;
  background: #fff;
  color: var(--Dark-Blue, #11365a);
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.16px;
  min-width: 85px;
}
.cart-items-wrapper-rewards {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  padding: 0 16px 0 16px;
}
.credits-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
}
.refer {
  color: #184e82;
  background: white;
  padding: 2px 10px;
  border-radius: 100px;
}
.faqs-section {
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  align-self: baseline;
  border-radius: 16px;
  background: #fff;
  align-self: stretch;
}
.faqs-title {
  color: #11365a;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.24px;
}
.general-faq {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 15px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  padding: 16px;
}
.faq-subtitle-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.faq-subtitle {
  color: #11365a;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: -0.24px;
}
.faq-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}
.faq-wrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-bottom: 1px solid #3e4855;
}
.faq-info-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
  gap: 20px;
  align-self: stretch;
  width: 100%;
}
.faq-title {
  color: #11365a;
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.18px;
  text-align: left;
}
.faq-description {
  color: var(--Dark-Blue, #11365a);
  font-family: "Montserrat-Medium", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.32px;
  align-self: stretch;
  opacity: 0.9;
  text-align: left;
}
.rotate-icon {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}
.referralcode-wrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: flex-start;
}

@media only screen and (max-width: 767px) {
  .table-container {
    padding-top: 1rem;
  }
  .frame-35732 {
    padding: 0rem 1rem;
  }
  .frame-357322 {
    flex-direction: column;
  }
  .reward-card {
    flex-direction: column;
  }
  .reward-title-wrapper,
  .claim-reward,
  .reward-title2 {
    align-self: stretch;
  }
  .claim-reward {
    height: 40px;
  }
  .title2 {
    font-size: 20px;
    font-weight: 600;
  }
  /* .sent-invites-wrapper {
    margin-bottom: 1rem;
  } */
  .reward-title1 {
    text-align: center;
  }
  .title,
  .balance-amount {
    font-size: 18px;
  }
  .refer {
    font-size: 14px;
  }
  .current-credits {
    padding: 20px;
    height: auto;
    gap: 15px;
  }
  .faqs-title {
    font-size: 18px;
  }
  .credits-wrapper {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
  .referral-title-0 {
    font-size: 16px;
  }
  .sent-invites-title:nth-of-type(1) {
    flex-direction: column;
    padding: 16px;
    align-items: flex-start;
  }
  .sentinvites-wrapper {
    gap: 0;
  }
  .cart-items-wrapper-rewards {
    gap: 5px;
  }
  .sub-frame-357322 {
    gap: 15px;
  }
  .faqs-section {
    margin-bottom: 15px;
  }
  .wrapper-1 {
    padding: 0.5rem 0rem !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1156px) {
}
@media only screen and (min-width: 1156px) {
}
</style>
