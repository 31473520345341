<template>
  <div
    class="warning-wrapper"
    :style="{
      background: this.$root.warning === 'EMPTY' ? '#861f1f' : '#F0AD4E',
      color: this.$root.warning === 'EMPTY' ? 'white' : '',
    }"
  >
    <div class="warning-title">
      <svg
        v-if="this.$root.warning === 'EMPTY'"
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="37"
        viewBox="0 0 36 37"
        fill="none"
      >
        <path
          d="M22.5 14.4714L13.5 23.4714"
          stroke="white"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3.879 26.0604C3.31635 25.498 3.00017 24.735 3 23.9394V14.0034C3.00017 13.2079 3.31635 12.4449 3.879 11.8824L10.911 4.85044C11.4735 4.28779 12.2364 3.97161 13.032 3.97144H22.968C23.7636 3.97161 24.5265 4.28779 25.089 4.85044L32.121 11.8824C32.6836 12.4449 32.9998 13.2079 33 14.0034V23.9394C32.9998 24.735 32.6836 25.498 32.121 26.0604L25.089 33.0924C24.5265 33.6551 23.7636 33.9713 22.968 33.9714H13.032C12.2364 33.9713 11.4735 33.6551 10.911 33.0924L3.879 26.0604Z"
          stroke="white"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.5 14.4714L22.5 23.4714"
          stroke="white"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <svg
        v-if="this.$root.warning === 'LOW'"
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="37"
        viewBox="0 0 36 37"
        fill="none"
      >
        <path
          d="M32.5946 27.9715L20.5946 6.97153C20.333 6.50983 19.9536 6.12581 19.495 5.85863C19.0365 5.59145 18.5153 5.45068 17.9846 5.45068C17.454 5.45068 16.9328 5.59145 16.4743 5.85863C16.0157 6.12581 15.6363 6.50983 15.3747 6.97153L3.37465 27.9715C3.11017 28.4296 2.97149 28.9494 2.97266 29.4783C2.97383 30.0072 3.11482 30.5264 3.38132 30.9833C3.64782 31.4401 4.03037 31.8184 4.49019 32.0798C4.95001 32.3411 5.47076 32.4763 5.99965 32.4715H29.9997C30.526 32.471 31.0429 32.332 31.4986 32.0685C31.9542 31.8049 32.3325 31.4262 32.5954 30.9702C32.8584 30.5142 32.9967 29.9971 32.9966 29.4708C32.9964 28.9444 32.8578 28.4274 32.5946 27.9715Z"
          stroke="#2c3e50"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18 14.4714V20.4714"
          stroke="#2c3e50"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18 26.4714H18.015"
          stroke="#2c3e50"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <p>
        {{
          this.$root.warning === "EMPTY"
            ? "Insufficient Group Credits. Please recharge by navigating to Credits tab"
            : "Your group credits balance is low. Please recharge by navigating to Credits tab"
        }}
      </p>
    </div>
    <button
      class="warning-action"
      :style="{
        border:
          this.$root.warning === 'EMPTY'
            ? '1.5px solid #fff'
            : '1.5px solid #2c3e50',
        color: this.$root.warning === 'EMPTY' ? 'white' : '#2c3e50',
      }"
      @click="redirectToCredits"
    >
      Credits
    </button>
  </div>
</template>
<script>
export default {
  name: "WarningMessage",
  methods: {
    redirectToCredits() {
      this.$router.push({ path: "/profile", query: { tab: "credits" } });
    },
  },
};
</script>
<style scoped>
.warning-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 4vw;
  pointer-events: auto;
  font-weight: 600;
}
.warning-title {
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  text-align: left;
}
.warning-action {
  border-radius: 10px;
  background: transparent;
  cursor: pointer;
}
</style>
