<template>
  <div
    :class="[
      'table-container',
      { 'logged-in': !isMobile, 'not-logged-in': isMobile, collapsed: this.$root.isCollapsed && !isMobile, notification: this.$root.warning && this.$root.warning !== 'NORMAL' && !isMobile,},
    ]"
  >
    <div class="frame-35732">
      <div class="frame-35733" v-if="userType">
        <div
          class="tab-1"
          style="box-shadow: 0px -4px 24px 0px #8fc8ff; background: white"
        >
          Sample Reports
        </div>
      </div>
      <div
        class="tables-wrapper"
        :style="{ borderTopLeftRadius: userType ? '0' : '16px' }"
        v-if="searchResults1 && searchResults1?.length > 0"
      >
        <div
          class="table-wrapper"
          v-if="searchResults1 && searchResults1?.length !== 0"
        >
          <div class="table-title-wrapper" v-if="!userType">
            <div class="table-title">Sample Reports</div>
          </div>
          <!-- Desktop -->
          <table
            id="searchResultTable"
            v-if="!mobile && searchResults1 && searchResults1?.length > 0"
          >
            <thead style="background: #ecf4ff">
              <tr>
                <th v-for="col in repositoryTabelColumns" :key="col">
                  {{ col }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(searchResult, index) in searchResults1"
                :style="hoveredRow1 === index ? hoverStyle : {}"
                :key="index"
              >
                <td style="text-transform: capitalize">
                  {{ searchResult?.name?.toLowerCase() }}
                </td>
                <td>
                  {{ searchResult?.country }}
                </td>
                <td class="action-buttons">
                  <div
                    class="reorder-button-outer"
                    title="Download Report"
                    :disabled="
                      !searchResult?.reportFileKey ||
                      (searchResult?.validityDate !== undefined &&
                        isValidityDateOver(searchResult?.validityDate))
                    "
                    :style="{
                      opacity:
                        (searchResult?.validityDate !== undefined &&
                          !isValidityDateOver(searchResult?.validityDate)) ||
                        searchResult?.reportFileKey
                          ? '1'
                          : '0.5',
                      cursor:
                        (searchResult?.validityDate !== undefined &&
                          !isValidityDateOver(searchResult?.validityDate)) ||
                        searchResult?.reportFileKey
                          ? 'pointer'
                          : 'not-allowed',
                    }"
                    @click="
                      downloadReport(
                        searchResult?.reportFileKey,
                        searchResult?.name?.toLowerCase(),
                        searchResult?.id
                      )
                    "
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20.2502 13.5001C20.4492 13.5001 20.6399 13.5791 20.7806 13.7198C20.9212 13.8604 21.0002 14.0512 21.0002 14.2501V19.5001C21.0002 19.8979 20.8422 20.2794 20.5609 20.5608C20.2796 20.8421 19.8981 21.0001 19.5002 21.0001H4.50024C4.10242 21.0001 3.72089 20.8421 3.43958 20.5607C3.15828 20.2794 3.00024 19.8979 3.00024 19.5001L3.00024 14.2501C3.00024 14.0512 3.07926 13.8604 3.21991 13.7198C3.36057 13.5791 3.55133 13.5001 3.75024 13.5001C3.94916 13.5001 4.13992 13.5791 4.28057 13.7198C4.42123 13.8604 4.50024 14.0512 4.50024 14.2501L4.50024 19.5001H19.5002V14.2501C19.5002 14.0512 19.5793 13.8604 19.7199 13.7198C19.8606 13.5791 20.0513 13.5001 20.2502 13.5001Z"
                        fill="#11365a"
                      />
                      <path
                        d="M7.71962 11.0301L11.4696 14.7801C11.5393 14.8499 11.622 14.9052 11.713 14.9429C11.8041 14.9807 11.9017 15.0001 12.0002 15.0001C12.0988 15.0001 12.1964 14.9807 12.2874 14.9429C12.3785 14.9052 12.4612 14.8499 12.5309 14.7801L16.2809 11.0301C16.4216 10.8894 16.5007 10.6985 16.5007 10.4995C16.5007 10.3005 16.4216 10.1096 16.2809 9.96889C16.1401 9.82816 15.9493 9.74909 15.7502 9.74909C15.5512 9.74909 15.3603 9.82816 15.2196 9.96889L12.7502 12.4392V3.74951C12.7502 3.5506 12.6712 3.35983 12.5306 3.21918C12.3899 3.07853 12.1992 2.99951 12.0002 2.99951C11.8013 2.99951 11.6106 3.07853 11.4699 3.21918C11.3293 3.35983 11.2502 3.5506 11.2502 3.74951V12.4392L8.78087 9.96889C8.64014 9.82816 8.44927 9.74909 8.25024 9.74909C8.05122 9.74909 7.86035 9.82816 7.71962 9.96889C7.57889 10.1096 7.49983 10.3005 7.49983 10.4995C7.49983 10.6985 7.57889 10.8894 7.71962 11.0301Z"
                        fill="#11365a"
                      />
                    </svg>
                    <!-- <span class="re-order">Download</span> -->
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- Mobile -->
          <div
            id="searchResultDiv"
            v-if="mobile && searchResults1 && searchResults1?.length > 0"
          >
            <div
              v-for="(searchResult, index) in searchResults1"
              :key="index"
              class="result-wrapper"
              :style="hoveredRow === index ? hoverStyle : {}"
              @mouseover="setHover(index)"
              @mouseout="clearHover"
            >
              <div class="row">
                <div class="label">Company Name:</div>
                <div class="value">{{ searchResult?.name?.toLowerCase() }}</div>
              </div>
              <div class="row">
                <div class="label">Country:</div>
                <div class="value">{{ searchResult.country }}</div>
              </div>
              <div
                class="reorder-button-outer"
                title="Download Report ( Note: Report will be delivered to the registered Email )"
                :disabled="
                  !searchResult?.reportFileKey ||
                  (searchResult?.validityDate !== undefined &&
                    isValidityDateOver(searchResult?.validityDate))
                "
                :style="{
                  opacity:
                    (searchResult?.validityDate !== undefined &&
                      !isValidityDateOver(searchResult?.validityDate)) ||
                    searchResult?.reportFileKey
                      ? '1'
                      : '0.5',
                  cursor:
                    (searchResult?.validityDate !== undefined &&
                      !isValidityDateOver(searchResult?.validityDate)) ||
                    searchResult?.reportFileKey
                      ? 'pointer'
                      : 'not-allowed',
                }"
                @click="
                  downloadReport(
                    searchResult?.reportFileKey,
                    searchResult?.name?.toLowerCase(),
                    searchResult?.id
                  )
                "
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.2502 13.5001C20.4492 13.5001 20.6399 13.5791 20.7806 13.7198C20.9212 13.8604 21.0002 14.0512 21.0002 14.2501V19.5001C21.0002 19.8979 20.8422 20.2794 20.5609 20.5608C20.2796 20.8421 19.8981 21.0001 19.5002 21.0001H4.50024C4.10242 21.0001 3.72089 20.8421 3.43958 20.5607C3.15828 20.2794 3.00024 19.8979 3.00024 19.5001L3.00024 14.2501C3.00024 14.0512 3.07926 13.8604 3.21991 13.7198C3.36057 13.5791 3.55133 13.5001 3.75024 13.5001C3.94916 13.5001 4.13992 13.5791 4.28057 13.7198C4.42123 13.8604 4.50024 14.0512 4.50024 14.2501L4.50024 19.5001H19.5002V14.2501C19.5002 14.0512 19.5793 13.8604 19.7199 13.7198C19.8606 13.5791 20.0513 13.5001 20.2502 13.5001Z"
                    fill="white"
                  />
                  <path
                    d="M7.71962 11.0301L11.4696 14.7801C11.5393 14.8499 11.622 14.9052 11.713 14.9429C11.8041 14.9807 11.9017 15.0001 12.0002 15.0001C12.0988 15.0001 12.1964 14.9807 12.2874 14.9429C12.3785 14.9052 12.4612 14.8499 12.5309 14.7801L16.2809 11.0301C16.4216 10.8894 16.5007 10.6985 16.5007 10.4995C16.5007 10.3005 16.4216 10.1096 16.2809 9.96889C16.1401 9.82816 15.9493 9.74909 15.7502 9.74909C15.5512 9.74909 15.3603 9.82816 15.2196 9.96889L12.7502 12.4392V3.74951C12.7502 3.5506 12.6712 3.35983 12.5306 3.21918C12.3899 3.07853 12.1992 2.99951 12.0002 2.99951C11.8013 2.99951 11.6106 3.07853 11.4699 3.21918C11.3293 3.35983 11.2502 3.5506 11.2502 3.74951V12.4392L8.78087 9.96889C8.64014 9.82816 8.44927 9.74909 8.25024 9.74909C8.05122 9.74909 7.86035 9.82816 7.71962 9.96889C7.57889 10.1096 7.49983 10.3005 7.49983 10.4995C7.49983 10.6985 7.57889 10.8894 7.71962 11.0301Z"
                    fill="white"
                  />
                </svg>
                <span class="re-order">Download</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Toastify ref="toastContainer" />
</template>
<script>
import VueCookies from "vue-cookies";
import Toastify from "@/components/Toastify.vue";
import config from "@/config.js";

import { DownloadReportPDF } from "@/api/APIs";

export default {
  name: "SampleReports",
  components: {
    Toastify,
  },
  data() {
    return {
      selectedResult: null,
      isSafari: false,
      hoveredRow: null,
      hoveredRow1: null,
      repositoryTabelColumns: [
        "Company Name",
        "Country",
        "Actions",
      ],
      searchResults1: config.reportData,
      isMobile: window.innerWidth < 1023,
      mobile: window.innerWidth <= 767,
    };
  },
  computed: {
    userType() {
      return ["HEAD", "MANAGER", "EXECUTIVE", "BUSINESS-ADMIN"].includes(
        VueCookies.get("user-type")
      );
    },
    hoverStyle() {
      return this.isSafari
        ? {
            borderRadius: "13px",
            background: "#11365a1f",
          }
        : {
            borderRadius: "13px",
            background:
              // "#f2f2f2",
              "linear-gradient(133deg, rgba(38, 187, 194, 0.2) 27.88%, rgba(24, 207, 215, 0) 79.91%), #3673ae14",
          };
    },
  },
  mounted() {
    this.isSafari = this.detectSafari();
    window.addEventListener("resize", this.handleResponsive);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResponsive);
  },
  methods: {
    isValidityDateOver(validityDate) {
      const today = new Date();
      const validity = new Date(validityDate);
      return validity < today;
    },
    async downloadReport(fileKey, fileName, id) {
      const capitalizedFileName = fileName
        ?.toLowerCase()
        .replace(/^(.)|\s+(.)/g, function ($1) {
          return $1.toUpperCase();
        });
      let payload = {
        fileKey: fileKey,
      };
      try {
        const response = await DownloadReportPDF(payload);
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(
          new File(
            [response.data],
            `${capitalizedFileName} Credence Report-${id}.pdf`,
            {
              type: "application/pdf",
            }
          )
        );
        link.download = `${capitalizedFileName} Credence Report-${id}.pdf`;
        document.body.append(link);
        link.click();
        link.remove();
        // in case the Blob uses a lot of memory
        setTimeout(() => URL.revokeObjectURL(link.href), 7000);
      } catch (error) {
        if (error.response && error.response.status !== 403) {
          return this.$refs.toastContainer.addToast(
            "Oops! Something went wrong, Please try again later",
            {
              type: "error",
            }
          );
        }
      }
    },
    handleResponsive() {
      this.isMobile = window.innerWidth < 1023;
    },
    detectSafari() {
      const ua = navigator.userAgent.toLowerCase();
      return ua.indexOf("safari") !== -1 && ua.indexOf("chrome") === -1;
    },
    setHover(index) {
      this.hoveredRow = index;
    },
    clearHover() {
      this.hoveredRow = null;
    },
  },
};
</script>
<style scoped>
.logged-in {
  margin-left: 18vw;
  padding-top: 55px !important;
  transition: margin-left 0.3s ease;
}
.logged-in.collapsed {
  margin-left: 60px;
}
.logged-in.notification {
  padding-top: 105px !important;
}
.not-logged-in {
  width: 100%;
}
#searchResultTable {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
  border-radius: 16px;
  overflow: hidden;
  font-family: "Montserrat-Regular", sans-serif;
}
.tables-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 2rem 1.5rem;
  border-radius: 16px;
  background-color: white;
  gap: 2rem;
  position: relative;
}
.table-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 2rem;
}
.table-title-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.table-title {
  color: #11365a;
  font-family: "Montserrat-Regular", sans-serif;
  line-height: 180%;
  text-align: left;
  font-size: 20px;
  font-weight: 500;
}
#searchResultTable th {
  text-align: left;
  font-family: "Montserrat-Regular", sans-serif;
  color: #11365a;
  padding: 10px 16px;
  min-width: 30px;
}

#searchResultTable td {
  text-align: left;
  padding: 10px 16px;
  color: #11365a;
  font-size: 16px;
  font-weight: 400;
  line-height: 175%;
  letter-spacing: -0.16px;
}
.table-container,
.table-container * {
  box-sizing: border-box;
}
.table-container {
  padding-top: 4rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  /* margin-left: 18vw; */
}
.action-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 20px;
}
.reorder-button-outer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  text-align: left;
}
.reorder-button-border {
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 5px 10px;
}
.re-order {
  color: #11365a;
  font-family: "Montserrat-Regular", sans-serif;
  line-height: 180%;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
}
.frame-35732 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 2rem 3rem;
}
.frame-35733 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 30px;
  width: 100%;
}
.tab-1 {
  display: flex;
  min-width: 190px;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 16px 16px 0px 0px;
  background: #ecf4ff;
  color: var(--Dark-Blue, #11365a);
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.4px;
}

@media only screen and (max-width: 767px) {
  .table-container {
    padding-top: 1rem;
  }
  .tables-wrapper {
    padding: 2rem 1rem;
  }
  .table-wrapper {
    gap: 1rem;
  }
  #searchResultDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    align-self: stretch;
    width: 100%;
    gap: 20px;
  }
  .result-wrapper {
    display: flex;
    padding: 21.634px;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    align-self: stretch;
    border-radius: 21.634px;
    border: 1.352px solid rgba(0, 0, 0, 0.15);
    background: #fff;
  }
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    color: #11365a;
    gap: 20px;
  }
  .label {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    color: #11365a;
    font-family: "Montserrat-Regular", sans-serif;
    text-align: left;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    /* line-height: 37.859px; */
    letter-spacing: -0.216px;
    width: 20vw;
  }
  .value {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    color: #11365a;
    font-family: "Montserrat-Regular", sans-serif;
    text-align: left;
    font-size: 16px;
    font-style: normal;
    /* font-weight: 400; */
    letter-spacing: -0.216px;
    text-transform: capitalize;
    flex: 1;
  }
  .reorder-button-outer {
    width: 100%;
  }
  .frame-35732 {
    padding: 0rem 0.8rem;
  }
  .table-title {
    font-size: 18px;
  }
  .tables-wrapper {
    padding: 1rem 0.8rem;
  }
  .result-wrapper {
    padding: 20px 20px 10px 20px;
  }
  .reorder-button-outer {
    margin-top: 10px;
    background: #11365a;
    align-items: center;
    justify-content: center;
    padding: 2px 0;
    border-radius: 10px;
  }
  .reorder-button-outer span {
    color: white;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1156px) {
  .table-container {
    padding-top: 0;
  }
}
@media only screen and (min-width: 1156px) {
}
</style>
